@import '../../../../styles/src/lib/media.css';

.base {
  position: relative;
  z-index: 0;

  @media (--breakpoint-sm-up) {
    width: 100%;
  }

  @media (--breakpoint-sm-down) {
    height: 100%;
    display: flex;
    flex-direction: column;

    & > * {
      flex-grow: 1;
    }
  }
}

.back-button-wrapper {
  position: absolute;
  z-index: 1;
  left: calc(var(--u-spacing-24) * -1);
  top: var(--u-spacing-16);
  transform: translateX(-100%);

  @media (--breakpoint-md-down) {
    display: none;
  }
}

.toast-wrapper {
  &--top {
    z-index: 3;
    position: fixed;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding-left: var(--u-spacing-32);
    padding-right: var(--u-spacing-32);

    @media (--breakpoint-sm-up) {
      max-width: calc(350px + calc(var(--u-spacing-32) * 2));
      top: var(--u-spacing-104);
    }

    @media (--breakpoint-sm-down) {
      top: var(--u-spacing-40);
    }
  }

  &:not(&--top) {
    max-width: calc(350px + calc(var(--u-spacing-24) * 2));
    padding-left: var(--u-spacing-24);
    padding-right: var(--u-spacing-24);
  }
}

.main-wrapper {
  flex-grow: 1;
  display: flex;
}
