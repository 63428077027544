.base {
  display: flex;
  min-height: 56px;

  background-color: var(--u-color-white);
  border-radius: var(--u-border-radius-12);
  box-shadow: 0 0 0 1px var(--u-color-royal-100);
}

.tooltip-outer {
  display: flex;
}

.main {
  flex-grow: 1;
  display: flex;
  align-items: center;
  padding: var(--u-spacing-8) var(--u-spacing-16) var(--u-spacing-8)
    var(--u-spacing-12);
  overflow: hidden;
}

.content {
  min-width: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: var(--u-spacing-2);
}

.address {
  word-break: break-all;
  color: var(--u-color-black);
  font-size: var(--u-text-small-font-size);
  line-height: var(--u-text-small-line-height);

  &--truncated {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.logo-wrapper {
  display: flex;
  width: 32px;
  height: 32px;
  padding: var(--u-spacing-4);
  margin-right: var(--u-spacing-4);
}

.copy-button {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.copy-button-content {
  display: inline-flex;
}
