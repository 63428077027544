.base {
  background-color: var(--u-color-white);
  color: var(--u-color-neutral-500);
  border: 1px solid var(--u-color-neutral-500);
  padding: var(--u-spacing-4);
  border-radius: 100px;
  display: flex;
  align-items: center;
  user-select: none;
  max-height: 32px;

  &:hover {
    background-color: var(--u-color-royal-050);
  }
}

.left-icon {
  width: 24px;
  height: 24px;
  border-radius: 9999px;
  overflow: hidden;

  & > svg,
  img {
    width: 100%;
    height: 100%;
  }
}
