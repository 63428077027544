.trigger {
  &[data-state='open'] {
    .button-arrow {
      transform: rotate(-180deg);
    }
  }

  &[data-state='close'] {
    .button-arrow {
      transform: rotate(-180deg);
    }
  }
}

.button-arrow {
  transition-property: transform;
  transition-duration: 300ms;
  margin-right: var(--u-spacing-4);
  color: inherit;
}

.item {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-4);
  padding: var(--u-spacing-2) var(--u-spacing-4);

  &:focus {
    outline: 0;
  }
}

.content {
  padding: var(--u-spacing-8);
  background-color: var(--u-color-white);
  border-radius: var(--u-border-radius-12);
  border: 1px solid var(--u-color-royal-100);
  box-shadow: 0px 4px 10px 0px #6932d41a;

  display: flex;
  flex-direction: column;
  gap: var(--u-spacing-4);
  animation-duration: 500ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-side='bottom'] {
    animation-name: slideDownAndFade;
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
