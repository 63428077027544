.base {
  overflow: hidden;
  padding: 0;
  height: 56px;
  background-color: var(--u-color-white);

  border: 1px solid var(--u-color-royal-100);
  border-radius: var(--u-border-radius-12);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:is(:focus-visible, :hover),
  &.is-selected {
    /* stylelint-disable indentation */
    background-image: linear-gradient(
        var(--u-color-white),
        var(--u-color-white)
      ),
      linear-gradient(90deg, #6932d4 0%, #ff0bd8 100%);
    /* stylelint-enable indentation */

    border-color: transparent;
    box-shadow: var(--u-box-shadow-soft);
    background-origin: border-box;
    background-clip: content-box, border-box;
  }

  transition: border-color, box-shadow, 0.2s ease-in-out;

  &:disabled {
    pointer-events: none;

    .icon-container {
      opacity: 0.5;
    }
  }
}

.inner-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--u-spacing-16);
  padding: var(--u-spacing-8) var(--u-spacing-12);
}

.left-container {
  display: flex;
  align-items: center;
  gap: var(--u-spacing-4);
}

.icon-container {
  width: 32px;
  height: 32px;
  padding: var(--u-spacing-4);
}

.name-description-container {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.stack-assets-wrapper {
  margin-top: var(--u-spacing-12);
  display: flex;
  align-items: center;
}

.stack-assets-container {
  display: flex;
  align-items: center;
}

.stack-asset-container {
  display: flex;

  &:not(:first-child) {
    width: 34px;
    height: 34px;
    align-items: center;
    justify-content: center;
    background-color: var(--u-color-white);
    border-radius: 50%;
    margin-left: -7px;
  }

  &:nth-child(2) {
    margin-left: -6px;
  }

  &:not(:first-child, :nth-child(2)) {
    margin-left: -12px;
  }
}

.stack-asset {
  width: 24px;
  height: 24px;
}
