.base {
  padding-left: var(--u-spacing-24);
  padding-right: var(--u-spacing-24);
  padding-top: var(--u-spacing-20);
  padding-bottom: var(--u-spacing-12);

  display: grid;
  grid-template-columns: 24px 1fr 24px;
  align-items: center;
}

.content {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 2 / 3;
}
